import React, {useEffect, useRef} from 'react'
import SectionMaker from "../../components/SectionMaker";
import langStaticPaths from "../../helpers/langStaticPaths";
import setLang from "../../helpers/setLang";
import BigLogo from "../../components/BigLogo";
import { NextSeo } from 'next-seo';
import fetchHome from "@data/fetchHomepage";
import fetchGlobalData from "@data/fetchGlobalData";
import {REVALIDATE_TIME} from "../../revalidate.config";

const Index = ({sections, details}) => {

  // console.log('### INDEX ###');

  const scrolled = useRef(false);
  const smallLogoHidden = useRef(false);

  useEffect(() => {
    let windowY = 0;
    let windowW = window.innerWidth;

    const scrollDistanceTrigger = 50
    const BigLogoElem = document.getElementById('BigLogo');
    const SmallLogoElem = document.getElementById('SmallLogo');

    const hideSmallLogo = () => {
      if(SmallLogoElem) SmallLogoElem.style.opacity = 0;
      BigLogoElem.style.opacity = 1;
      smallLogoHidden.current = true
    };
    const showSmallLogo = () => {
      if(SmallLogoElem) SmallLogoElem.style.opacity = 1;
      BigLogoElem.style.opacity = 0;
      smallLogoHidden.current = false
    };

    const updateScrolled = () => {
      if(windowY > scrollDistanceTrigger && !scrolled.current) {
        scrolled.current = true;
      }
      if(windowY <= scrollDistanceTrigger && scrolled.current) {
        scrolled.current = false;
      }
    };


    if(windowW >= 1000) {
      hideSmallLogo()
    }


    const handleResize = () => {
      windowW = window.innerWidth;
      windowY = window.scrollY;
      updateScrolled();
      if(windowW < 1000 && smallLogoHidden.current) {
        showSmallLogo();
      }
      if(windowW >= 1000 && !smallLogoHidden.current && !scrolled.current) {
        hideSmallLogo()
      }
    };


    const handleScroll = () => {
      windowY = window.scrollY;

      if(windowW >= 1000) {
        if(windowY > scrollDistanceTrigger && !scrolled.current) {
          updateScrolled();
          showSmallLogo()
        }
        if(windowY <= scrollDistanceTrigger && scrolled.current) {
          updateScrolled();
          hideSmallLogo()
        }
      }

    };


    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      SmallLogoElem.style.opacity = 1

    }
  });

  return <>
    <NextSeo  {...details.seo} />
    <BigLogo/>
    {
      sections.map((section, i) => <SectionMaker key={i} {...section} />)
    }
  </>;
};


Index.showBigLogo = true;
Index.headerTheme = "WHITE";

export async function getStaticProps({ params, preview, previewData }) {
  setLang(params.lang);

  const responses = await Promise.all([
    fetchHome(preview, previewData),
    fetchGlobalData()
  ]);

  const details = responses[0].details;
  const sections = responses[0].sections;
  const globalData = responses[1];

  return {
    props: {sections, details, globalData},
    revalidate: REVALIDATE_TIME
  }
}

export async function getStaticPaths() {
  return { paths: langStaticPaths(), fallback: false }
}


export default Index
